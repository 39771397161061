import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(<App />, document.getElementById('root'));

//TODO: PREFLIGHT: serviceworker - turn off dev build first!
serviceWorkerRegistration.register();